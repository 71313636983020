import { Link } from "react-router-dom";

const RelatedHates = ({ related }) => {
  return (
    <>
      <h3>Powiązane hejty:</h3>
      <ul>
        {related.map((hate, key) => (
          <Link to={`/${hate.slug}`} key={key}>
            <li>{hate.title}</li>
          </Link>
        ))}
      </ul>
    </>
  );
};

export default RelatedHates;
