import { useEffect, useState } from "react";
import sanity from "../sanity/sanity";
import { Link } from "react-router-dom";

const Navigation = () => {
  const [hates, setHates] = useState([]);

  useEffect(() => {
    sanity
      .fetch(
        `*[_type == "hate"]
        {
          title, slug
        }
        `
      )
      .then((result) => {
        setHates(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <h2>Wszystkie hejty:</h2>
      <ul>
        {hates.map((hate, key) => (
          <Link to={`/${hate.slug}`} key={key}>
            <li>{hate.title}</li>
          </Link>
        ))}
      </ul>
    </>
  );
};

export default Navigation;
