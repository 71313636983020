import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import sanity from "./sanity/sanity";
import RelatedHates from "./components/RelatedHates";

const Page = () => {
  const [hate, setHate] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    sanity
      .fetch(
        `*[_type == "hate" && slug == "${slug}"]
        {
          title,
          slug,
          author -> {name, surname},
          "related": relatedHates[] -> {title, slug}
        }[0]
        `
      )
      .then((result) => {
        setHate(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [slug]);

  return (
    <>
      <h2>{hate.title}</h2>
      {hate.author ? (
        <p>
          by{" "}
          <em>
            {hate.author.name} {hate.author.surname}
          </em>
        </p>
      ) : (
        ""
      )}
      {hate.related ? <RelatedHates related={hate.related} /> : ""}
    </>
  );
};
export default Page;
