import "./App.css";
import Navigation from "./components/Navigation";
import Page from "./Page";
import { BrowserRouter, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="">
      <BrowserRouter>
        <header className="">
          <h1>Nienawidzę kodu - blog!!!</h1>
          <Navigation />
        </header>
        <Switch>
          <Route path="/:slug" component={Page} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
